import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { PIXEL_INITIALIZER_PROVIDER } from './initializers/pixel.initializer';
import { PixelSettings } from './interfaces/pixel-settings.interface';
import { PIXEL_SETTINGS_TOKEN } from './tokens/pixel-settings.token';

export function provideEnvironmentPixel(configValue?: PixelSettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: PIXEL_SETTINGS_TOKEN,
      useValue: configValue,
    },
    PIXEL_INITIALIZER_PROVIDER,
  ]);
}
