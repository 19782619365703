import { APP_INITIALIZER, Provider } from '@angular/core';
import { PIXEL_SETTINGS_TOKEN } from '../tokens/pixel-settings.token';

export function pixelInitializer() {
  return async () => true;
}

export const PIXEL_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: pixelInitializer,
  deps: [PIXEL_SETTINGS_TOKEN],
};
