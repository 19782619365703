import { Injectable } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AccountingOnboarding } from '@dougs/accounting/onboarding/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Company } from '@dougs/company/dto';
import { ServicesState } from '@dougs/revenue/services/dto';
import { UserSignup } from '@dougs/signup/dto';
import { User } from '@dougs/user/dto';
import { ClarityService } from './clarity';
import { HubspotService } from './hubspot';
import { MixpanelService } from './mixpanel';
import { PixelService } from './pixel';

export type MetricsEvent = {
  category?: string;
  label?: string;
  action: string;
};

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  identified = false;

  constructor(
    private readonly gaService: GoogleAnalyticsService,
    private readonly mixpanelService: MixpanelService,
    private readonly hubspotService: HubspotService,
    private readonly pixelService: PixelService,
    private readonly clarityService: ClarityService,
    private readonly intercom: Intercom,
  ) {}

  identify(
    loggedInUser: User,
    activeUser: User,
    companyServicesState: ServicesState,
    activeCompany: Company,
    accountingOnboarding: AccountingOnboarding | null,
    activeAccountingYear?: AccountingYear,
  ): void {
    this.gaService.set('userId', loggedInUser.id);
    this.mixpanelService.identify(
      loggedInUser,
      activeUser,
      companyServicesState,
      activeCompany,
      accountingOnboarding,
      activeAccountingYear,
    );
    this.hubspotService.identify(loggedInUser.id, loggedInUser.email);
  }

  addSignupUserData(user: Partial<UserSignup>): void {
    this.mixpanelService.setSignupUserData(user);
  }

  pushPageview(pageName: string): void {
    this.hubspotService.trackPageView(pageName);
  }

  pushGAEvent(event: string | MetricsEvent): void {
    if (typeof event === 'string') {
      this.gaService.event(event);
    } else {
      this.gaService.event(event.action, event.category, event.label);
    }
  }

  pushMixpanelEvent(event: string | MetricsEvent, data: Record<string, unknown> = {}): void {
    if (typeof event === 'string') {
      void this.mixpanelService.trackEvent(event, data);
    } else {
      void this.mixpanelService.trackEvent(this.stringifyMetricsEvent(event), data);
    }
  }

  pushMixpanelTimeEvent(eventName: string): void {
    this.mixpanelService.timeEvent(eventName);
  }

  pushIntercomEvent(eventName: string, data: Record<string, unknown> = {}): void {
    this.intercom.trackEvent(eventName, data);
  }

  loadPixel(): void {
    this.pixelService.loadPixel();
  }

  loadClarity(): void {
    this.clarityService.loadClarity();
  }

  unloadClarity(): void {
    this.clarityService.unloadClarity();
  }

  pushIntercomAttributes(data: Record<string, unknown> = {}): void {
    this.intercom.update(data);
  }

  private stringifyMetricsEvent(event: MetricsEvent): string {
    return [event.category, event.label, event.action].filter((exists) => exists).join(' ');
  }
}
