import { Inject, Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountingOnboarding } from '@dougs/accounting/onboarding/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ServicesState } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserSignup } from '@dougs/signup/dto';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { MixpanelSettings } from '../interfaces/mixpanel-settings.interface';
import { MIXPANEL_SETTINGS_TOKEN } from '../tokens/mixpanel-settings.token';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor(
    @Inject(MIXPANEL_SETTINGS_TOKEN) private readonly settings: MixpanelSettings,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
  ) {
    this.init();
  }

  init(): void {
    if (!this.settings.trackingCode) {
      return;
    }

    mixpanel.init(this.settings.trackingCode, { batch_flush_interval_ms: 1000 });
    mixpanel.register({
      Platform: this.settings.platform,
    });
  }

  identify(
    loggedInUser: User,
    activeUser: User,
    companyServicesState: ServicesState,
    activeCompany: Company,
    accountingOnboarding: AccountingOnboarding | null,
    activeAccountingYear?: AccountingYear,
  ): void {
    if (!this.settings.trackingCode) {
      return;
    }

    mixpanel.identify(loggedInUser.id.toString());
    this.setUserData(
      loggedInUser,
      activeUser,
      companyServicesState,
      activeCompany,
      accountingOnboarding,
      activeAccountingYear,
    );
  }

  setUserData(
    loggedInUser: User,
    activeUser: User,
    companyServicesState: ServicesState,
    activeCompany: Company,
    accountingOnboarding: AccountingOnboarding | null,
    activeAccountingYear?: AccountingYear,
  ): void {
    mixpanel.people.set({
      $email: loggedInUser.email,
      $first_name: loggedInUser.profile?.firstName,
      $last_name: loggedInUser.profile?.lastName,
      $created: loggedInUser.createdAt,
      'Company Name': activeCompany?.legalName,
      'Company Legal Form': activeCompany?.legalForm,
      'Company Tax Regime': activeCompany?.taxRegime,
      'Company is First Fiscal Year': !!(loggedInUser.id === activeUser.id
        ? activeAccountingYear?.isCompanyFirstAccountingYear
        : null),
      'Company Activity Starts at': activeCompany?.activityStartsAt,
      'Company Closing Date': loggedInUser.id === activeUser.id ? activeAccountingYear?.effectiveClosingDate : null,
      'Company is Ecommerce': activeCompany?.accountingConfiguration?.hasEcommerceDispatch,
      'is Social Customer': activeCompany?.isSocialCustomer,
      'Social Plan Cancelled at': activeCompany?.metadata.socialSuspendedAt,
      'Accounting Plan Type':
        loggedInUser.id === activeUser.id ? companyServicesState?.accounting?.serviceCodeLabel : null,
      'Accounting Plan is Free for Ever':
        loggedInUser.id === activeUser.id ? companyServicesState?.accounting?.isFree : true,
      'is Accounting Trial': !!(loggedInUser.id === activeUser.id
        ? companyServicesState?.accounting?.isTrialing
        : null),
      'is Accounting Customer': !!(loggedInUser.id === activeUser.id
        ? companyServicesState?.accounting?.isActive
        : null),
      'is Created by Dougs': activeCompany?.isCreatedByDougs,
      'Accounting Plan Activated at':
        loggedInUser.id === activeUser.id ? companyServicesState?.accounting?.activatedAt : null,
      'Accounting Plan Cancelled at':
        loggedInUser.id === activeUser.id ? companyServicesState?.accounting?.suspendedAt : null,
      'Company APE Code': activeCompany?.headquarter?.ape,
      'Company has Employees': activeCompany?.hasEmployee,
      'Company VAT Regime': activeCompany?.vatConfiguration?.type,
      'is Team Dougs': loggedInUser.isAccountantOrAdmin,
      'Company has EU Stock': activeCompany?.salesViaPlatformConfiguration?.individualSalesFromUeStock,
      'Company does DropShipping from MarketPlace':
        activeCompany?.salesViaPlatformConfiguration.dropShippingUnder150FromMarketPlace,
      'Company does BtoC Sales in EU': activeCompany?.salesViaPlatformConfiguration.individualEuropeanSales,
      'Company has E-commerce business': activeCompany?.salesViaPlatformConfiguration.salesViaPlatform,
      'Company has IOSS Declaration': activeCompany?.accountingConfiguration.hasIoss,
      'Company has OSS-EU Declaration': activeCompany?.accountingConfiguration.hasOss,
      'Company has Ecommerce Dispatch': activeCompany?.accountingConfiguration.hasEcommerceDispatch,
      'Company does DropShipping over 150': activeCompany?.salesViaPlatformConfiguration.dropShippingOver150,
      'Company Flags Assigned': activeCompany?.flags,
      'User Flags Assigned': loggedInUser.flags,
      'has Dark Mode': loggedInUser.metadata?.darkMode === 'dark' || loggedInUser.metadata?.darkMode === 'system',
      'Settings Completion Percent': activeCompany?.completion?.percent,
      'has Checklist Active': !!accountingOnboarding?.data.checklist?.enabledAt,
    });
  }

  setSignupUserData(user: Partial<UserSignup>): void {
    mixpanel.people.set({
      $first_name: user.firstName,
      $last_name: user.lastName,
      'Company Name': user.companyDetails?.legalName,
      'Company Activity Starts at': user.companyDetails?.activityStartsAt,
      'Company APE Code': user.companyDetails?.headquarter?.ape,
      'Company Legal Form': user.legalInformation?.legalForm
        ? user.legalInformation?.legalForm
        : user.companyDetails?.legalForm,
      'Company Tax Regime': user.legalInformation?.taxRegime,
      'Company VAT Regime': user.legalInformation?.hasMonthlyVat ? 'standardMonthly' : 'none',
    });
  }

  async trackEvent(eventName: string, data: Record<string, unknown> = {}): Promise<void> {
    if (
      this.userStateService.hasState &&
      this.userStateService.loggedInUser &&
      this.userStateService.activeUser &&
      this.userStateService.loggedInUser.id === this.userStateService.activeUser.id
    ) {
      let activeAccountingYear: AccountingYear | undefined;
      if (this.companyServicesStateService.services?.accounting?.shouldShowModuleLink) {
        activeAccountingYear = await lastValueFrom(this.accountingYearStateService.activeAccountingYear$.pipe(take(1)));
      }

      data = {
        ...data,
        'is Accounting Trial': this.companyServicesStateService.services?.accounting?.isTrialing,
        'is Accounting Customer': this.companyServicesStateService.services?.accounting?.isActive,
        'Company is First Fiscal Year': activeAccountingYear?.isCompanyFirstAccountingYear || null,
      };
    }
    mixpanel.track(eventName, data);
  }

  timeEvent(eventName: string): void {
    mixpanel.time_event(eventName);
  }
}
