import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PixelSettings } from '../interfaces/pixel-settings.interface';
import { PIXEL_SETTINGS_TOKEN } from '../tokens/pixel-settings.token';

@Injectable({
  providedIn: 'root',
})
export class PixelService {
  constructor(
    @Inject(PIXEL_SETTINGS_TOKEN) private readonly settings: PixelSettings,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public loadPixel() {
    if (this.document.getElementById('pixeljs')) {
      return;
    }
    const script: HTMLScriptElement = this.document.createElement('script');
    script.id = 'pixeljs';
    script.type = 'text/javascript';
    script.text = `!function(f,b,e,v,n,t,s) {
      if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${this.settings.trackingCode}');
      fbq('track', 'PageView');`;
    this.document.head.appendChild(script);
    const img: HTMLImageElement = this.document.createElement('img');
    img.setAttribute('src', `https://www.facebook.com/tr?id=${this.settings.trackingCode}&ev=PageView&noscript=1`);
    img.setAttribute('height', '1');
    img.setAttribute('width', '1');
    img.setAttribute('style', 'display:none');
    const noscript: HTMLElement = this.document.createElement('noscript');
    noscript.appendChild(img);
    this.document.head.appendChild(noscript);
  }
}
