import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountingOnboarding } from '@dougs/accounting/onboarding/dto';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ServicesState } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { MetricsService } from '../metrics.service';

@Injectable({
  providedIn: 'root',
})
export class MetricsResolverService {
  constructor(
    private readonly metricsService: MetricsService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
  ) {}

  async resolve(): Promise<void> {
    const activeUser: User = this.userStateService.activeUser;
    if (!activeUser) {
      return;
    }
    const activeCompany: Company = this.companyStateService.activeCompany;
    const companyServicesState: ServicesState = this.companyServicesStateService.services;
    let activeAccountingYear: AccountingYear | undefined;
    if (companyServicesState?.accounting.shouldShowModuleLink) {
      await this.accountingYearStateService.refreshActiveAccountingYear(activeCompany.id);
      activeAccountingYear = await lastValueFrom(this.accountingYearStateService.activeAccountingYear$.pipe(take(1)));
    }
    await this.accountingOnboardingStateService.refreshAccountingOnboarding(activeCompany.id);
    const accountingOnboarding: AccountingOnboarding | null = await lastValueFrom(
      this.accountingOnboardingStateService.accountingOnboarding$.pipe(take(1)),
    );

    this.metricsService.identify(
      this.userStateService.loggedInUser,
      activeUser,
      companyServicesState,
      activeCompany,
      accountingOnboarding,
      activeAccountingYear,
    );
    this.metricsService.loadPixel();
  }
}
